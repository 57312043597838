import { BrowserRouter, Route, Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

export default function NavigationBar({logoSRC}){
    return(
        <Navbar bg="light" expand="lg" className="nav">
            <Container fluid>
                <Navbar.Brand href="/"><img src={logoSRC} className="logo" alt="logo"/></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                <Nav
                    className="me-auto my-2 my-lg-0"
                    // style={{ maxHeight: '100px' }}
                    navbarScroll
                >
                    <Nav.Link href="/#services">Our Services</Nav.Link>
                    <Nav.Link href="/#aboutus">About Us</Nav.Link>
                    <Nav.Link href="/contact">Contact Us</Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        // <nav className="nav">
        //     <span>
        //     <Link to="/" className="nav-item"><img src={logoSRC} className="logo" alt="logo"/></Link>
        //         {/* <a href={logohref}
        //             className="nav-item"><img src={logoSRC} className="logo" alt="logo"/></a> */}
                    
        //     </span>
        //     <span>
        //         {/* <NavItems item={"Our Services"} href={"/#services"}/> */}
        //         <a href={"/#aboutus"} className="nav-item">About Us</a>
        //         <a href={"/#services"} className="nav-item">Our Services</a>
        //         {/* <NavItems item={"About Us"} href={"/#aboutus"}/> */}
        //         <NavItems item={"Contact Us"} href={"/contact"} />
        //     </span>
        // </nav>
    );
}

function NavItems({item, href}){
    return(
        <Link to={href} className="nav-item">{item}</Link>
    // <a href={href}
    //     className="nav-item">{item}</a>
    );
}