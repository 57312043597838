import USAID from "../Images/USAID.svg";
import WHO from "../Images/WHO.svg";
import PlanetProjects from "../Images/PlanetProjects.svg";

const cardnames = [
    { id: 1, src: USAID, alt: "USAID"},
    { id: 2, src: WHO, alt: "PlanetProjects"},
    { id: 3, src: PlanetProjects, alt: "WHO"}]


export default function PastClients() {
    const listItems = cardnames.map(card =>
        <li key={card.id} className="client">
            <Card src={card.src} alt={card.alt}/>
        </li>
    );
    return(
        <div className="past-clients" id="past-clients">
            <h1 id="banner-head">Our Past Clients</h1>
            <hr id="past-clients-hr"/>
            {/* <img src={src} className="clients" alt="past clients"/> */}
            <ul className="clients">{listItems}</ul>
        </div>
    );
}

function Card({src, alt}){
    return(
        // <div className="card">
            <img src={src} alt={alt} className="card-img"/>
        // </div>
    );
}