import './Contact.css';
import { useState } from 'react';

const inputs = [{id:1, type:"text",label:"Your Name"},{id:2, type:"text", label: "Your Email Adress"},{id:3, type:"text",label:"Subject of Your Message"},{id:4, type:"textarea",label:"Your Message"}]
export default function Contact() {
    return (
            <div id='contact'>
                <h1>Contact Us</h1>
                <span>Send us a message filing the form below</span>
                <Inputs inputs={inputs}/>
            </div>
        );
}

function Inputs() {
    
    const [error, setError] = useState(null);
    const [status, setStatus] = useState('typing');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    let disabled = true;
    let buttonClass;
    let values = {name, email, subject, message};

    function handleNameChange(e) {
        setName(e.target.value);
    }
    function handleEmailChange(e) {
        setEmail(e.target.value);
    }
    function handleSubjectChange(e) {
        setSubject(e.target.value);
    }
    function handleMessageChange(e) {
        setMessage(e.target.value);
    }

    if (status === 'success') {
        return <h1>That's right!</h1>
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setStatus('submitting');
        try {
        //   await submitForm(answer);
          setStatus('success');
        } catch (err) {
          setStatus('typing');
          setError(err);
        }
      }
      if(email.length === 0|| name.length === 0|| message.length === 0|| subject.length === 0||
        status === 'submitting'){
            disabled = true;
            buttonClass = "disabled";
        } else{
            disabled = false;
            buttonClass = "submit";
        }

    return (
        <form className='textinputs'>
           
                <div className='input-container'>
                    <label>Your Name</label>
                    <input type="text" className="inputs" value={name} onChange={handleNameChange} disabled={status === 'submitting'}/>
                </div>
                <div className='input-container'>
                    <label>Your Email Adress</label>
                    <input type="text" className="inputs" value={email} onChange={handleEmailChange} disabled={status === 'submitting'}/>
                </div>
                <div className='input-container'>
                    <label>Subject of Your Message</label>
                    <input type="text" className="inputs" value={subject} onChange={handleSubjectChange} disabled={status === 'submitting'}/>
                </div>
                <div className='input-container'>
                    <label>Your Message</label>
                    <textarea className="inputs" rows={'5'} value={message} onChange={handleMessageChange} disabled={status === 'submitting'}/>
                </div>                
            

            <input  value={'submit'} type={'button'} className={buttonClass} disabled={disabled} onClick={()=>console.log(values)}/>
        </form>
    );
}