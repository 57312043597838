import DAH  from "../Images/DistributionandHaulage.svg";
import ASALF from "../Images/AirSeaandFreight.svg";
import CCD from "../Images/ColdChainDistributions.svg";
import CCAD from "../Images/CustomsandClearanceDelivery.svg";
import W from "../Images/Warehousing.svg";
import PARR from "../Images/PackagingandRemoval.svg";

const services = [
    {id: 1, image: DAH , header: "Distribution and Haulage", body: "We are commited to providing high quality haulage and distribution services through our team of efficient transport workers."},
    {id: 2, image: ASALF, header: "Air, Sea and Land Freight", body: "We offer land freight services using safe, innovative and deep-rooted dekivery networks across the globe, transporting freight of practically any size shape and type."},
    {id: 3, image: CCD, header: "Cold Chain Distribution", body: "Using our fleet of cold-chain trucks, we provide you with quality and on-time distribution of your cold-chain goods well preserved and in perfect condition."},
    {id: 4, image: CCAD, header: "Customs Clearance and Delivery", body: "We represent our clients in customs examination, valuation, payment of duty and delivery of cargo to customs after clearance along with documents."},
    {id: 5, image: W, header: "Warehousing", body: "We are partenered with several warehouses ensuring that your goods aresafely stored from the period of clearance till the period of delivery."},
    {id: 6, image: PARR, header: "Packaging and Removal Relocation", body: "We offer reliable, cost effective, punctual and timely home and office international or domestic relocation services."}
]

export default function Services(){
    const servicesList = services.map(service =>
        {if (service.id % 2 == 0) {
            return(
            <li key={service.id} className="left service">
            
                <div className="image">
                    <img src={service.image} alt={service.header} className="content-image"/>
                </div>
                <div className="content">
                    <h2 className="content-header">{service.header}</h2>
                    <span>{service.body}</span>
                </div>
            </li>)
        }else{
            return(
            <li key={service.id} className="right service">
            
                
                <div className="content">
                    <h2 className="content-header">{service.header}</h2>
                    <span>{service.body}</span>
                </div>
                <div className="image">
                    <img src={service.image} alt={service.header} className="content-image"/>
                </div>
            </li>)
        }}
        
    );

    return (
        <div id="services">
            <h1 className="services-header">Our Services</h1>
            <ul className="services">{servicesList}</ul>
        </div>
        
    );
}