export default function Banner({src}) {
    return(
        <div className="banner">
            <img src={src} alt="banner" className="banner-image"/>
            <div id="banner-text">
                <div className="banner-title">
                    <h1 id="banner-head">Expedite Movers</h1>
                    <hr className="hr"/>
                </div>
                <br/>
                <span id="banner-body">Logistics services you can trust for speed, care, and constant communication</span>
            </div>
        </div>
    );
}