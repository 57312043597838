import Banner from "./components/Banner.js";
import PastClients from "./components/PastClients.js";
import logo from './Images/ExpediteLogo.svg'
import banner from "./Images/unsplash_ki19VJAGh6w.svg";
import Services from './components/Services';

let page = "index";

export default function Home() {
    return(
        <>
            <Banner src={banner}/>
            <PastClients />
            <Services />
        </>
    );    
}
