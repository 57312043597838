// import logo from './logo.svg';
import './App.css';
import Navbar from './components/Nav.js';
import Banner from "./components/Banner.js";
import PastClients from "./components/PastClients.js";
import logo from './Images/ExpediteLogo.svg'
import banner from "./Images/unsplash_ki19VJAGh6w.svg";
import Services from './components/Services';
import Footer from './components/Footer';
import Home  from "./Home";
import Contact from "./Contact";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <div className="App">
        <div className='container-'>
          <Navbar logoSRC={logo} />
          <div>
            <Routes>
              <Route path="/" element={<Home/>}>
                <Route index element={<Home/>}/>  
              </Route>
              <Route path='/contact' element={<Contact/>}/>
            </Routes>
            </div>
          <Footer/>
        </div>
      </div>
    </Router>
  );
}

export default App;
