import logo from '../Images/ExpediteLogo.svg'
import { Row, Col } from 'react-bootstrap';


const footer = [{id: 1, header: "Site Map", links:[ 
        { id:1,name: "Homepage", link:"/"},
        { id:2,name: "Our Services", link:"/#services"},
        { id:3,name: "About Us", link:"/#aboutus"},
        { id:4,name: "Contact Us", link:"/contact"}]},
    {id: 2, header: "Social Media", links:[ 
        { id:1,name: "Facebook", link:"#"},
        { id:2,name: "Twitter", link:"#"},
        { id:3,name: "Instagram", link:"#"},
        { id:4,name: "LinkedIn", link:"#"}]},
    {id: 3, header: "Contact Us", links:[ 
        { id:1,name: "+234 8023062321", link:"tel:+2348023062321"},
        { id:2,name: "+234 12914338", link:"tel:+23412914338"},
        { id:3,name: "+234 9134310067", link:"tel:+2349134310067"},
        { id:4,name: "contact@expeditemoversng.com", link:"mailto:contact@expeditemoversng.com"},
        { id:5,name: "info@expeditemoversng.com", link:"mailto:info@expeditemoversng.com"},
        { id:6,name: "expeditemovers@gmail.com", link:"mailto:expeditemovers@gmail.com"}]}
    ]

export default function Footer(){
    const siteDetails = footer.map(detail => 
        <Col sm={"12"} md={"3"}key={detail.id} className='sitedetail'>
          <SiteDetails details={detail.links} header={detail.header}/>
        </Col>  
      );
    return(
        <footer id='aboutus'>
            <Row>
                <Col sm={"12"} md={"3"} className='sitedetail-logo'>
                    <img src={logo} alt="Expedite logo" id='expedite'/>
                    <p> Expedite Movers Ltd. is a highly dynamic logistics service provider positioned to render efficient and personalized service to her esteemed clients with satisfaction guaranteed.</p>
                </Col>
                {/* <div className='links'> */}
                    {siteDetails}
                {/* </div> */}
            </Row>
        </footer>
    );
}

function SiteDetails({details, header}){
    const siteDetails = details.map(detail => 
      <li key={detail.id}>
        <a href={detail.link} className="detail-link">{detail.name}</a>
      </li>  
    );

    return(
        <div>
            <h3>{header}</h3>
            <ul className='detail'>{siteDetails}</ul>
        </div>
    );
}